import Lottie from 'lottie-web'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import tw, { css, theme } from 'twin.macro'
import { global } from '../styles/global'
import { max, min } from '../utils/breakpoints'
import { LinkType, SVGType } from '../utils/prop-types'
import Button from './button'
import Link from './link'
import MainNav from './main-nav'
import Text from './text'
import MarketSelector from './market-selector'

const Header = ({
  logoLinkUrl,
  logoWhiteAccent,
  logoCyanAccent,
  callToAction,
  topNavItems,
  navItems,
  socialMedia,
  copyright,
  customerArea,
  marketSelector,
  headerTransition,
  headerTransitionColor,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isFirstOptionMenu, setIsFirstOptionMenu] = useState(true)
  const [detectScroll, setDetectScroll] = useState(false)
  const [detectHover, setDetectHover] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const logoDiv = useRef()
  const handleHover = (hover) => setDetectHover(hover)
  const [scrollDirection, setScrollDirection] = useState('up')
  let oldScroll = 0

  const handleScroll = debounce(() => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }

    setScrollDirection(oldScroll > window.pageYOffset ? 'up' : 'down')
    oldScroll = window.pageYOffset
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  const loadAnimation = (json) => {
    return Lottie.loadAnimation({
      container: logoDiv.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: json,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMax meet',
      },
    })
  }

  useEffect(() => {
    const displayWhiteLogo =
      !detectScroll &&
      !detectHover &&
      headerTransition &&
      headerTransitionColor === 'white' &&
      !isOpened

    setIsMobile(
      parseFloat(document.documentElement.style.getPropertyValue('--vw').slice(0, -2)) < 10.24
    )

    const selectedLogo = (() => {
      if (displayWhiteLogo) return logoWhiteAccent
      if (isMobile && isOpened) return logoWhiteAccent
      return logoCyanAccent
    })()

    setIsFirstOptionMenu(!window.location.pathname.includes('customer-service'))

    const animation = loadAnimation(selectedLogo)
    return () => {
      animation.destroy()
    }
  }, [detectScroll, detectHover, headerTransition, headerTransitionColor, isOpened])

  return (
    <header>
      <div
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        css={[
          css`
            ${tw`fixed inset-x-0 top-0 z-20 transition-all duration-300 ease-in-out bg-transparent h-14`} {
              background: linear-gradient(
                to left,
                ${theme`colors.secondary.500`} 50%,
                ${headerTransition && !detectScroll && !detectHover
                  ? 'transparent 50%'
                  : 'white 50%'}
              );
              background-size: 200% 100%;
              background-position: left bottom;
            }
            ${min.lg} {
              ${tw`h-14`}
              &:hover {
                ${tw`bg-white`}
              }
            }
          `,
          headerTransition && !detectScroll && !detectHover ? tw`bg-grey-light` : tw`bg-white`,
          isOpened &&
            css`
              ${max.lg} {
                ${tw`bg-right-bottom`}
              }
            `,
        ]}
      >
        <div
          css={[
            global`layout.container`,
            global`layout.grid`,
            tw`items-center h-full`,
            isMobile && !isOpened && scrollDirection == 'down' && tw`hidden`,
          ]}
        >
          <div style={tw`flex items-center`}>
            <Link
              to={topNavItems[0].link.link}
              style={tw`mr-6 md:(mr-10)`}
              onClick={() => setIsOpened(false)}
            >
              <Text
                content={topNavItems[0].label}
                textType="subtitle"
                css={[tw`font-light`, isFirstOptionMenu && tw`font-medium`]}
              />
            </Link>
            <Link to={topNavItems[1]?.link?.link} onClick={() => setIsOpened(false)}>
              <Text
                content={topNavItems[1]?.label}
                textType="subtitle"
                css={[tw`font-light`, !isFirstOptionMenu && tw`font-medium`]}
              />
            </Link>
          </div>
          <div
            css={[
              tw`flex items-center justify-end whitespace-nowrap
              col-end-5
              md:(col-end-9) 
              lg:(h-full col-end-13)
              xl:(col-end-17)`,
            ]}
          >
            <Link
              to={customerArea.subLinks[0]?.link}
              css={tw`col-span-1 ml-11`}
              onClick={() => setIsOpened(false)}
            >
              <Text
                content={customerArea.label}
                textType="subtitle"
                css={[tw`font-light`, !isFirstOptionMenu && tw`font-medium`]}
              />
            </Link>
          </div>
        </div>
        <div
          css={[
            tw`absolute inset-x-0 bottom-0 block h-px -z-10 lg:(hover:bg-line-grey)`,
            isOpened && tw`hidden lg:(bg-secondary-500)`,
            detectHover && tw`bg-line-grey`,
            detectScroll && tw`bg-line-grey`,
          ]}
        />
        <div
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          css={[
            css`
              ${tw`fixed inset-x-0 top-14 z-20 transition-all duration-300 ease-in-out bg-transparent h-header-mobile`}
              ${max.lg} {
                background: linear-gradient(
                  to left,
                  ${theme`colors.secondary.500`} 50%,
                  ${headerTransition && !detectScroll && !detectHover
                    ? 'transparent 50%'
                    : 'white 50%'}
                );
                background-size: 200% 100%;
                background-position: left bottom;
              }
              ${min.lg} {
                ${tw`h-header-default`}
                &:hover {
                  ${tw`bg-white`}
                }
              }
            `,
            headerTransition && !detectScroll && !detectHover ? tw`bg-transparent` : tw`bg-white`,
            isOpened &&
              css`
                ${max.lg} {
                  ${tw`bg-right-bottom`}
                }
              `,
            isMobile && !isOpened && scrollDirection == 'down' && tw`top-0`,
          ]}
        >
          <div css={[global`layout.container`, global`layout.grid`, tw`items-center h-full`]}>
            <Link to={logoLinkUrl} style={tw`col-span-2`} onClick={() => setIsOpened(false)}>
              <div ref={logoDiv} css={tw`h-logo-mobile lg:h-logo-default`} />
            </Link>
            <div
              css={[
                tw`col-start-4 col-end-5 
                md:(col-start-8 col-end-9) 
                lg:(col-start-3 col-end-10 h-full px-4) 
                xl:(col-start-4 col-end-12 px-0)`,
                !isFirstOptionMenu && tw`hidden`,
              ]}
            >
              <MainNav
                navItems={navItems}
                socialMedia={socialMedia}
                callToAction={callToAction}
                customerArea={customerArea}
                copyright={copyright}
                isOpened={isOpened}
                setIsOpened={setIsOpened}
                handleHover={handleHover}
                headerTransition={headerTransition}
                headerTransitionColor={headerTransitionColor}
                marketSelector={marketSelector}
                detectHover={detectHover}
              />
            </div>
            <div
              css={[
                tw`hidden lg:(flex justify-end items-center h-full col-start-12 col-end-13)
          xl:(col-start-12 col-end-17)`,
              ]}
            >
              <Button
                type="primary"
                size="xs"
                label={callToAction.label}
                link={callToAction.link}
                theme="navy-cyan"
                css={[
                  tw`hidden whitespace-nowrap lg:hidden`,
                  isFirstOptionMenu && tw`hidden whitespace-nowrap lg:block`,
                ]}
                hideCaret
              />
              <MarketSelector link={marketSelector[0]} style={tw`lg:(block ml-7)`} />
            </div>
          </div>
          <div
            css={[
              tw`absolute inset-x-0 bottom-0 block h-px -z-10 lg:(hover:bg-line-grey)`,
              isOpened && tw`hidden lg:(bg-secondary-500)`,
              detectScroll && tw`bg-line-grey`,
            ]}
          />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  logoLinkUrl: PropTypes.string,
  logo: SVGType,
  callToAction: LinkType,
  navItems: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  customerArea: LinkType,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
}

export default Header
